import useAuth from 'app/hooks/useAuth'
import { flat } from 'app/utils/utils'
import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AllPages } from '../routes/routes'

const AuthGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth()

    const [previousRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    const routes = flat(AllPages())

    console.log(user)

    useEffect(() => {
        if (previousRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previousRoute])

    if (isAuthenticated) return <>{children}</>
    else {
        return (
            <Navigate
                to="/session/signin"
                state={{ redirectUrl: previousRoute }}
            />
        )
    }
}

export default AuthGuard
