import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const InvoiceDelete = Loadable(lazy(() => import('./InvoiceDelete')))

const DeleteInvoiceRoutes = [
    {
        path: '/remove-invoice',
        element: <InvoiceDelete />,
        auth: authRoles.admin,
    },
]

export default DeleteInvoiceRoutes
