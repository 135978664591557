export const navigations = [
    {
        label: 'HOME',
        type: 'label',
    },
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    },
    {
        name: 'Analytics',
        path: '/dashboard/analytics',
        icon: 'analytics',
    },
    {
        label: 'INVOICE ACTIONS',
        type: 'label',
    },
    {
        name: 'Invoice Actions',
        icon: 'book',
        children: [
            {
                name: 'Create Invoice Form',
                path: '/create-invoice',
                icon: 'folder_open',
            },
            {
                name: 'Photo to XML Converter',
                path: '/convert-invoice',
                icon: 'camera',
            },
            {
                name: 'Upload Invoice',
                path: '/upload-invoice',
                icon: 'save',
            },
            {
                name: 'Remove Invoice',
                path: '/remove-invoice',
                icon: 'delete',
            },
            {
                name: 'Validate Invoice',
                path: '/validate-invoice',
                icon: 'edit',
            },
            {
                name: 'Download Invoice',
                path: '/download-invoice',
                icon: 'download',
            },
            {
                name: 'Send Invoice',
                path: '/send-invoice',
                icon: 'email',
            },
        ],
    },
    {
        label: 'External Links',
        type: 'label',
    },
    {
        name: 'Help',
        icon: 'lightbulb',
        type: 'extLink',
        path: 'https://seng-apple-pie.github.io/documentation/',
    },
    {
        name: 'FAQs',
        path: '/invoice-faqs',
        icon: 'question_mark',
    },
]
