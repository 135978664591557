import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import analyticsRoutes from 'app/views/analytics/AnalyticsRoute'
import convertPhotoToXMLRoutes from 'app/views/convert_photo_to_xml/PhotoToXMLRoute'
import createInvoiceRoutes from 'app/views/createInvoice/CreateInvoiceRoutes'
import sendInvoiceRoutes from 'app/views/sendInvoice/SendInvoiceRoutes'
import validateInvoiceRoutes from 'app/views/validateInvoice/ValidateInvoiceRoute'
import pasteInvoiceRoutes from 'app/views/pasteInvoice/PasteInvoiceRoute'
import deleteInvoiceRoutes from 'app/views/deleteInvoice/DeleteInvoiceRoute'
import receiveInvoiceRoutes from 'app/views/receiveInvoice/ReceiveInvoiceRoute'
import faqInvoiceRoutes from 'app/views/faqInvoice/FAQRoutes'
import profileRoutes from 'app/views/sessions/ProfileRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [...dashboardRoutes, ...createInvoiceRoutes, ...convertPhotoToXMLRoutes, ...pasteInvoiceRoutes, ...deleteInvoiceRoutes, ...validateInvoiceRoutes, ...sendInvoiceRoutes, ...receiveInvoiceRoutes, ...faqInvoiceRoutes, ...profileRoutes, ...analyticsRoutes],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="dashboard/default" />,
        },
        {
            path: '/',
            element: <Navigate to="dashboard/analytics" />,
        },
        {
            path: '/',
            element: <Navigate to="create-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="convert-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="delete-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="paste-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="validate-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="receive-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="send-invoice" />,
        },
        {
            path: '/',
            element: <Navigate to="invoice-faqs" />,
        },
        {
            path: '/',
            element: <Navigate to="session/user-profile" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
