import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const SendInvoicePage = Loadable(lazy(() => import('./SendInvoicePage.jsx')))

const SendInvoiceRoutes = [
    {
        path: '/send-invoice',
        element: <SendInvoicePage />,
        auth: authRoles.admin,
    },
]

export default SendInvoiceRoutes
