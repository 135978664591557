import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const AnalyticsPage = Loadable(lazy(() => import('./AnalyticsPage')))

const AnalyticsRoutes = [
    {
        path: '/dashboard/analytics',
        element: <AnalyticsPage />,
        auth: authRoles.admin,
    },
]

export default AnalyticsRoutes
