import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const FAQ = Loadable(lazy(() => import('./FAQ')))

const FAQRoutes = [
    {
        path: '/invoice-faqs',
        element: <FAQ />,
        auth: authRoles.admin,
    },
]

export default FAQRoutes
