import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const InvoiceValidate = Loadable(lazy(() => import('./InvoiceValidate')))

const ValidateInvoiceRoutes = [
    {
        path: '/validate-invoice',
        element: <InvoiceValidate />,
        auth: authRoles.admin,
    },
]

export default ValidateInvoiceRoutes
