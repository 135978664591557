import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Profile = Loadable(lazy(() => import('./Profile.jsx')))

const profileRoutes = [
    {
        path: '/session/user-profile',
        element: <Profile />,
        auth: authRoles.admin,
    },
]

export default profileRoutes
