import React, { useState, useEffect } from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate } from 'react-router-dom'

const App = () => {
    const all_pages = useRoutes(AllPages())
    const [data, setData] = useState([{}])
    let navigate = useNavigate();
    /*
    useEffect(() => {
        fetch("/healthcheck").then(
            res => res.json()
        ).then(
            data => {
                setData(data)
                console.log(data)
            }
        )
    })*/

    useHotkeys('ctrl+shift+c,command+option+shift+c,ctrl+shift+u,command+option+shift+u,ctrl+shift+d,command+option+shift+d,ctrl+shift+v,command+option+shift+v,ctrl+shift+h,command+option+shift+h,ctrl+shift+r,command+option+shift+r', (event, handler) => {
        let path = `/dashboard/default`; 
        switch (handler.key) {
            case 'ctrl+shift+c':case 'command+option+shift+c': path = `/create-invoice`;
                break;
            case 'ctrl+shift+u':case 'command+option+shift+u': path = `/upload-invoice`;
                break;
            case 'ctrl+shift+r':case 'command+option+shift+r': path = `/remove-invoice`;
                break;
            case 'ctrl+shift+v':case 'command+option+shift+v': path = `/validate-invoice`;
                break;
            case 'ctrl+shift+d':case 'command+option+shift+d': path = `/download-invoice`;
                break;
            case 'ctrl+shift+h':case 'command+option+shift+h': path = `/dashboard/default`;
                break;
            default: alert(event);
        }
        navigate(path)
        return false;
    });

    return (
        <Provider store={Store}>
            <SettingsProvider>
                <MatxTheme>
                    <AuthProvider>{all_pages}</AuthProvider>
                </MatxTheme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
